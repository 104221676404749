import React, { useState, useEffect, FC, useRef } from 'react';
import { Helmet } from 'react-helmet';
import { graphql } from 'gatsby';
import Seo from 'common/Seo';
import PageSchema from 'common/PageSchema';
import SignUpBanner from 'components/SignUpBanner';
import ProductsList from 'components/ProductsList';
import PriceSpider from 'common/PriceSpider';
import Banner from 'common/Banner';
import { getLangString, isElementInViewport } from 'utils/functions';
import { Layout } from '../../layout';
import './productsList.scss';

const ProductsListPage: FC<ProductsListTypes.ProductsListProps> = ({
  data: {
    allUmbracoProductsList: { nodes },
    productsListCategories: { productsCategories },
    products: { productsCards },
  },
}) => {
  const {
    langProps: { urls, lang },
    seoProps: { seoMetaTitle, seoMetaDescription, seoMetaKeywords, seoExternalHreflangs, ogImage },
    productsMainBanner: { content, bannerVariant, imageAsBackground, imageAsBackgroundAlt },
    menMainBanner,
    bannerImage,
    bannerFlourishImage,
    bannerContent,
    currentCategory,
    learnMoreLabel,
    loadMoreButton: { text },
    cardsPerLoad,
    productsTitle,
    productsDescription,
    showMenBannerCategory,
    showMenMainBanner,
  } = nodes[0];

  productsCards.forEach((productCard) => {
    if (productCard.variantProductCards) {
      productCard.variantProductCards?.map((item) => {
        const filteredVariant = productsCards.find((element) => element.url === item.url);
        if (filteredVariant) {
          item.localProductImage = filteredVariant.localProductImage;
        }
      });
    }
  });

  const [productsCardsToShow, setProductsCardsToShow] = useState(productsCards);

  const [cardsToLoad, setCardsToLoad] = useState(cardsPerLoad);
  const loadCards = (sliceTo) => {
    const slicedCards = productsCards.slice(0, sliceTo);
    setProductsCardsToShow([...slicedCards]);
  };
  const loadMoreRef = useRef<HTMLImageElement>(null);

  useEffect(() => {
    loadCards(cardsToLoad);
  }, [cardsToLoad]);

  const [bazaarVoice, setBazaarVoice] = useState(
    process.env.GATSBY_BAZAAR_VOICE_URL
      ? process.env.GATSBY_BAZAAR_VOICE_URL.replace('{lang}', getLangString(lang))
      : ''
  );

  useEffect(() => {
    process.env.GATSBY_BAZAAR_VOICE_URL &&
      setBazaarVoice(process.env.GATSBY_BAZAAR_VOICE_URL!.replace('{lang}', getLangString(lang)));
  }, [lang]);

  useEffect(() => window.scrollTo(0, 0), []);

  const handleShowMoreCards = () => {
    if (!loadMoreRef.current) {
      return;
    }

    if (isElementInViewport(loadMoreRef.current.getBoundingClientRect().bottom)) {
      setCardsToLoad((state) => state + cardsPerLoad);
      if (window.PriceSpider) {
        window.PriceSpider.rebind();
      }
    }
  };

  useEffect(() => {
    window.addEventListener('scroll', handleShowMoreCards);

    return () => window.removeEventListener('scroll', handleShowMoreCards);
  }, []);

  return (
    <Layout headerTransparent langProps={{ urls, lang }}>
      <Helmet>
        <script async src={bazaarVoice} type="text/javascript" />
      </Helmet>
      <Seo
        {...{
          title: seoMetaTitle,
          description: seoMetaDescription,
          keywords: seoMetaKeywords,
          externalHreflangs: seoExternalHreflangs,
          ogImage,
          lang,
        }}
      />
      <PageSchema
        type="WebPage"
        name={seoMetaTitle}
        data={{
          metaTitle: seoMetaTitle,
          metaDescription: seoMetaDescription,
          metaKeywords: seoMetaKeywords,
        }}
      />
      <PriceSpider lang={lang} />
      {menMainBanner && showMenBannerCategory === currentCategory && showMenMainBanner === '1' ? (
        <Banner
          {...{
            content: menMainBanner.content,
            bannerVariant: menMainBanner.bannerVariant,
            imageAsBackground: menMainBanner.imageAsBackground,
            imageAsBackgroundAlt: menMainBanner.imageAsBackgroundAlt,
          }}
        />
      ) : (
        <Banner
          {...{
            content: bannerContent || content,
            bannerVariant,
            imageAsBackground: bannerImage || imageAsBackground,
            imageAsBackgroundAlt,
            bannerFlourishImage,
          }}
        />
      )}
      <ProductsList
        {...{
          productsCategories,
          productsCards: productsCardsToShow,
          currentCategory,
          learnMoreLabel,
          productsTitle,
          productsDescription,
          showMenBannerCategory,
        }}
      />
      {productsCards.length > cardsToLoad ? (
        <div ref={loadMoreRef} className="products-list__button loader">
          {text}
        </div>
      ) : null}
      <SignUpBanner {...{ lang }} />
    </Layout>
  );
};

export const query = graphql`
  query UmbracoProductListQuery(
    $pageId: String
    $lang: String
    $variant: [String]
    $sortOrder: [SortOrderEnum]
    $sortName: [SalsifyProductsFieldsEnum]
  ) {
    allUmbracoProductsList(filter: { id: { eq: $pageId } }) {
      nodes {
        currentCategory: name
        productsTitle
        productsDescription
        learnMoreLabel
        cardsPerLoad
        sortProductsByName
        sortProductsName
        showMenBannerCategory
        showMenMainBanner
        loadMoreButton {
          text
          ariaLabel
        }
        langProps {
          urls {
            ...languageUrls
          }
          lang
        }
        seoProps {
          seoMetaTitle
          seoMetaKeywords
          seoMetaDescription
          seoExternalHreflangs {
            key
            value
          }
          ogImage
        }
        bannerImage {
          ...umbracoImage
        }
        bannerFlourishImage {
          ...umbracoImage
        }
        bannerContent
        productsMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
        menMainBanner {
          content
          bannerVariant
          imageAsBackgroundAlt
          imageAsBackground {
            fallbackUrl
            fluid {
              srcSet
              originalImg
              base64
            }
            variants {
              url
              type
              fallbackQuery
              alias
            }
          }
        }
      }
    }
    productsListCategories: allUmbracoProductsList(filter: { langProps: { lang: { eq: $lang } } }) {
      productsCategories: nodes {
        ariaLabel
        name
        link
        hideInCategoriesList
      }
    }
    products: allSalsifyProducts(
      filter: {
        tags: { elemMatch: { name: { in: $variant } } }
        langProps: { lang: { eq: $lang } }
        showProduct: { nin: "0" }
      }
      sort: { fields: $sortName, order: $sortOrder }
    ) {
      productsCards: nodes {
        productTitle
        productTitleWithSize
        url
        productSize
        productImage
        productEAN
        localProductImage {
          childImageSharp {
            fixed {
              ...GatsbyImageSharpFixed_withWebp
            }
          }
        }
        variantProductCards {
          title
          image
          size
          titleShort
          titleWithSize
          url
          ean
        }
      }
    }
  }
`;

export default ProductsListPage;
